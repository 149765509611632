<template>
  <div>
    <button
      v-b-modal="`rankingEditModal${data.level}`"
      class="btn btn-primary btn-sm"
    >
      <i class="fa fa-edit" /> แก้ไขข้อมูล
    </button>

    <b-modal
      :id="`rankingEditModal${data.level}`"
      v-model="modal"
      size="sm"
      title="แก้ไขข้อมูล"
      @ok="submit"
    >
      <b-form>
        <b-form-group
          label="ระดับ"
          label-for="level"
        >
          <b-form-input v-model="form.level" />
        </b-form-group>

        <div class="row">
          <div class="col-6">
            <b-form-group
              label="รายได้จากเงินคืน (THB)"
              label-for="cashback"
            >
              <b-form-input v-model="form.cashback" />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              label="ค่าคอมมิชชั่น (THB)"
              label-for="commission"
            >
              <b-form-input v-model="form.commission" />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              label="ยอดฝาก (THB)"
              label-for="min_dep"
            >
              <b-form-input v-model="form.min_dep" />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              label="ยอดเทิร์นสะสม (THB)"
              label-for="min_turn"
            >
              <b-form-input v-model="form.min_turn" />
            </b-form-group>
          </div>

          <div class="col-6">
            <b-form-group
              label="รายได้จากเงินคืน (KRW)"
              label-for="cashback"
            >
              <b-form-input v-model="form.cashback_kr" />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              label="ค่าคอมมิชชั่น (KRW)"
              label-for="commission"
            >
              <b-form-input v-model="form.commission_kr" />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              label="ยอดฝาก (KRW)"
              label-for="min_dep"
            >
              <b-form-input v-model="form.min_dep_kr" />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              label="ยอดเทิร์นสะสม (KRW)"
              label-for="min_turn"
            >
              <b-form-input v-model="form.min_turn_kr" />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group
              label="จำนวนสลากที่ซื้อได้"
              label-for="limit_lotto_point"
            >
              <b-form-input v-model="form.limit_lotto_point" />
            </b-form-group>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    BForm,
    BAlert,
    BSpinner,
    BFormCheckboxGroup,
    BFormCheckbox,
    BListGroupItem,
    BListGroup,
    BBadge,
    BOverlay,
    BIconController,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    BCardBody,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  filters: {
    convertTime(date) {
      return moment(date).tz('Asia/Bangkok').format('HH:ss')
    },
  },
  props: ['data'],
  emits: ['submit'],
  data: () => ({
    modal: false,
    form: {
      id: '',
      level: '',
      cashback: '',
      commission: '',
      point: '',
      min_dep: '',
      min_turn: '',

      cashback_kr: '',
      commission_kr: '',
      min_dep_kr: '',
      min_turn_kr: '',

      limit_lotto_point: ''
    },
  }),
  mounted() {
    this.form.id = this.$props.data.id
    this.form.level = this.$props.data.level
    this.form.cashback = this.$props.data.cashback
    this.form.commission = this.$props.data.commission
    this.form.point = this.$props.data.point
    this.form.min_dep = this.$props.data.min_dep
    this.form.min_turn = this.$props.data.min_turn

    this.form.cashback_kr = this.$props.data.cashback_kr
    this.form.commission_kr = this.$props.data.commission_kr
    this.form.min_dep_kr = this.$props.data.min_dep_kr
    this.form.min_turn_kr = this.$props.data.min_turn_kr

    this.form.limit_lotto_point = this.$props.data.limit_lotto_point
  },
  methods: {
    submit() {
      this.$emit('submit', this.form)
    },
  },
}
</script>
